<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Custom Bundle List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
        </va-data-table>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Bundle Name</span>
            <va-input
              v-model="bundle_name"
              placeholder="Enter your bundle name"
              :error="!!BundleNameErrors.length"
              :error-messages="BundleNameErrors"
            />
            <span class="va-form-label va-form-required-label">Select Bundle</span>
            <fieldset class="row" style="margin-left: 1px;">
              <va-radio-button
                option="Base Bundle"
                v-model="select_bundle"
                label="Base Bundle"
              />
              <va-radio-button
                option="Add-on Bundle"
                v-model="select_bundle"
                label="Add-on Bundle"
              />
            </fieldset>
            <div class="mt-2">
              <span class="va-form-label va-form-required-label">Allowed Type</span>
              <fieldset class="row" style="margin-left: 1px;">
                <va-radio-button
                  option="only_stb"
                  v-model="allowed_device"
                  label="Only STB"
                  @input="allowedBundle()"
                />
                <va-radio-button
                  option="only_app"
                  v-model="allowed_device"
                  label="Only APP"
                  @input="allowedBundle()"
                />
                <va-radio-button
                  option="stb_app"
                  v-model="allowed_device"
                  label="STB + APP"
                  @input="allowedBundle()"
                />
              </fieldset>
            </div>
            <div class="mt-4" v-if="select_bundle == 'Base Bundle'">
              <span class="va-form-required-label va-form-label">Bundle Mode</span>
              <va-select
                placeholder="Select Bundle Mode"
                v-model="bundle_mode"
                textBy="bundle_mode"
                @input='getBundlePrice(bundle_mode)'
                :options="bundleModeArr"
                :error="!!BundleModeErrors.length"
                :error-messages="BundleModeErrors"
              />
              <span class="va-form-label">OTT Bundle</span>
              <multiselect
                placeholder="Select Bundle"
                v-model="bundle"
                multiple="true"
                label="bundle_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                :options="bundleArr"
                @input="baseBundlePrice(bundle)"
              />
              <span class="va-form-label">IPTV Bundle</span>
              <multiselect
                v-model="addonbundle"
                placeholder="Select Addon Bundle"
                label="bundle_name"
                track-by="id"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                id="example"
                :multiple="true"
                :options="addonbundleArr"
                @input="addonBundlePrice(addonbundle)"
              />
            </div>
            <div class="mt-2" v-if="select_bundle == 'Add-on Bundle'">
              <span class="va-form-required-label va-form-label">Bundle Mode</span>
              <va-select
                placeholder="Select Bundle Mode"
                v-model="bundle_mode"
                textBy="bundle_mode"
                @input='getBundlePrice(bundle_mode)'
                :options="bundleModeArr"
                :error="!!BundleModeErrors.length"
                :error-messages="BundleModeErrors"
              />
              <span class="va-form-label va-form-required-label">Add on Bundle</span>
              <multiselect
                v-model="addonbundle"
                placeholder="Select Addon Bundle"
                label="bundle_name"
                track-by="id"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                id="example"
                :multiple="true"
                :options="addonbundleArr"
                @input="addonBundlePrice(addonbundle)"
              />
            </div>
            <div>
              <u><b>Price</b></u>
            </div>
            <div v-if="select_bundle == 'Base Bundle'">
              <span class="va-form-label">OTT Pricing</span>
              <va-input
                v-model="ott_price"
                type="text"
                :disabled="true"
              />
            </div>
            <span class="va-form-label">Recommended Cost</span>
            <va-input
              v-model="recommend_cost"
              type="text"
              :disabled="true"
            />
            <span class="va-form-required-label va-form-label">Seller Cost</span>
            <va-input
              v-model="seller_cost"
              type="number"
              placeholder="Enter your selling cost"
              :error="!!SellerCostErrors.length"
              :error-messages="SellerCostErrors"
            />
            <span class="va-form-required-label va-form-label">Operator Margin</span>
            <va-input
              v-model="operator_margin"
              type="number"
              placeholder="Enter your selling cost"
              :error="!!OperatorMarginErrors.length"
              :error-messages="OperatorMarginErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button type="submit" class="my-0" @click.prevent="createGroup()">Create</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
    <va-modal
      v-model="isshowmodal"
      size="large"
      class="flex md12"
      okText=" Update"
      cancelText=" Cancel"
      @ok="updateGroup()"
      @cancel="list()"
      no-outside-dismiss
    >
      <va-card title="Update Bundle">
        <form>
          <span class="va-form-label">Bundle Name</span>
          <va-input
            v-model="bundle_name"
            type="text"
            :disabled="true"
          />
          <span class="va-form-label">Select Bundle</span>
          <fieldset class="row" style="margin-left: 1px;">
            <va-radio-button
              option="Base Bundle"
              v-model="select_bundle"
              label="Base Bundle"
              :disabled="true"
            />
            <va-radio-button
              option="Add-on Bundle"
              v-model="select_bundle"
              label="Add-on Bundle"
              :disabled="true"
            />
          </fieldset>
          <span class="va-form-label">Allowed Type</span>
          <fieldset class="row" style="margin-left: 1px;">
            <va-radio-button
              option="only_stb"
              v-model="allowed_device"
              label="Only STB"
              :disabled="true"
            />
            <va-radio-button
              option="only_app"
              v-model="allowed_device"
              label="Only APP"
              :disabled="true"
            />
            <va-radio-button
              option="stb_app"
              v-model="allowed_device"
              label="STB + APP"
              :disabled="true"
            />
          </fieldset>
          <span class="va-form-label">Bundle Mode</span>
          <va-input
            v-model="bundle_mode"
            type="text"
            :disabled="true"
          />
          <div class="mt-2" v-if="select_bundle == 'Base Bundle'">
            <span class="va-form-label">Bundle</span>
            <va-input
              v-model="base_bundle_name"
              type="text"
              :disabled="true"
            />
            <span v-if="addonFlag" class="va-form-label">Add-on Bundle</span>
            <va-input
              v-if="addonFlag"
              v-model="add_on_bundle_name"
              type="text"
              :disabled="true"
            />
            <div>
              <u><b>Price</b></u>
            </div>
            <span class="va-form-label">OTT Pricing</span>
            <va-input
              v-model="ott_price"
              type="text"
              :disabled="true"
            />
          </div>
          <div class="mt-2" v-if="select_bundle == 'Add-on Bundle'">
            <span class="va-form-label">Add-on Bundle</span>
            <va-input
              v-model="add_on_bundle_name"
              type="text"
              :disabled="true"
            />
          </div>
          <span class="va-form-label">Recommended Cost</span>
          <va-input
            v-model="recommend_cost"
            type="text"
            :disabled="true"
          />
          <span class="va-form-required-label va-form-label">Seller Cost</span>
          <va-input
            v-model="seller_cost"
            type="text"
            placeholder="Enter your selling cost"
            :error="!!SellerCostErrors.length"
            :error-messages="SellerCostErrors"
          />
          <span class="va-form-required-label va-form-label">Operator Margin</span>
          <va-input
            v-model="operator_margin"
            type="text"
            placeholder="Enter your selling cost"
            :error="!!OperatorMarginErrors.length"
            :error-messages="OperatorMarginErrors"
          />
        </form>
      </va-card>
    </va-modal>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'app_setting',
  created () {
    this.getGroup();
    var reseller_org_id = Vue.$cookies.get('reseller_id')
    this.$http.get(config.menu.host + '/operator_setting/' + reseller_org_id).then(resp => {
      this.reseller_value = resp.body
    })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      resellerBundleList: [],
      bundle_name: '',
      BundleNameErrors: [],
      select_bundle: 'Base Bundle',
      bundle: [],
      bundleArr: [],
      addonbundle: [],
      addonbundleArr: [],
      ott_price: 0,
      recommend_cost: 0,
      seller_cost: 0,
      SellerCostErrors: [],
      isshowgrid: true,
      isshowForm: false,
      isshowmodal: false,
      showRemoveModal: false,
      msg: '',
      addon_price: '',
      bundleModeArr: [
        { id: 1, bundle_mode: 'One Month' },
        { id: 2, bundle_mode: 'Three Month' },
        { id: 3, bundle_mode: 'Six Month' },
        { id: 4, bundle_mode: 'Twelve Month' },
      ],
      BundleModeErrors: [],
      bundle_mode: '',
      cost_mode: {
        'One Month': 'price_one_month',
        'Three Month': 'price_three_month',
        'Six Month': 'price_six_month',
        'Twelve Month': 'price_twelve_month',
      },
      base_bundle_name: '',
      add_on_bundle_name: '',
      addonFlag: false,
      operator_margin: 0,
      OperatorMarginErrors: [],
      allowed_device: '',
      reseller_value: '',
            multiply_obj: {
        'One Month': 1,
        'Three Month': 3,
        'Six Month': 6,
        'Twelve Month': 12,
      },
      day_obj: {
        'One Month': 30,
        'Three Month': 90,
        'Six Month': 180,
        'Twelve Month': 365,
      },
    }
  },
  computed: {
    formReady () {
      return !this.SellerCostErrors.length &&
             !this.BundleNameErrors.length &&
             !this.BundleModeErrors.length &&
             !this.OperatorMarginErrors.length
    },
    fields () {
      return [{
        name: 'bundle_name',
        title: 'Bundle Name',
      },
      {
        name: 'bundle_mode',
        title: 'Bundle Mode',
      },
      {
        name: 'ott_price',
        title: 'OTT Price',
      },
      {
        name: 'recommend_cost',
        title: 'Recommendation Cost',
      },
      {
        name: 'seller_cost',
        title: 'Selling Cost',
      },
      {
        name: 'operator_margin',
        title: 'Operator Margin Cost',
      },
      {
        name: 'allow_type',
        title: 'Allowed Device',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.resellerBundleList)
    },
  },
  methods: {
    guid () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    },
    getGroup () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/bundle/get/reseller').then(response => {
        loader.hide()
        var bundle_data = response.body
        bundle_data.map(function(item){
          if (item.allowed_device == 'stb_app') {
            return item.allow_type = 'STB+APP'
          } else if (item.allowed_device == 'only_app') {
            return item.allow_type = 'APP'
          } else if (item.allowed_device == 'only_stb') {
            return item.allow_type = 'STB'
          }
        })
        this.resellerBundleList = bundle_data
        this.isshowgrid = true
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    allowedBundle () {
      this.bundle_mode = ''
      this.bundle = ''
      this.addonbundle = ''
    },
    getBundlePrice (mode) {
      var arr = []; var vm = this
      this.addonbundle = ''
      this.bundle = ''
      this.ott_price = 0
      this.recommend_cost = 0
      this.$http.get(config.menu.host + '/bundle').then(res => {
        res.body.map(function (item, i) {
          item.id = i + 1
          if (!item.add_on && (vm.allowed_device == item.allowed_device)) {
            arr.push(item)
          }
          if (i + 1 == res.body.length) {
            vm.bundleArr = arr.filter(function (data) {
              data.reseller_custom_bundle_id = data.bundle_id
              return data.bundle_mode == mode.bundle_mode
            })
            // vm.addonbundleArr = arr.filter()
          }
        })
      })
      this.$http.get(config.menu.host + '/bundle/get/addon').then(respo => {
        var addarr = []
        respo.body.map(function (item, i) {
          item.id = i + 1
          item.reseller_custom_bundle_id = item.bundle_id
          if (vm.allowed_device == item.allowed_device) {
            addarr.push(item)
          }
        })
        this.addonbundleArr = addarr
      })
    },
    baseBundlePrice (data) {
      var price = 0; var vm = this
      data.map(function (bundle_data, i) {
        var price_mode = vm.cost_mode[bundle_data.bundle_mode]
        price += bundle_data[price_mode]
      })
      this.ott_price = price
      this.recommend_cost = this.ott_price + this.addon_price
    },
    addonBundlePrice (data) {
	      var price = 0; var vm = this
      data.map(function (add_on_data, i) {
        if(vm.bundle_mode.bundle_mode == add_on_data.bundle_mode){
          var price_mode = vm.cost_mode[add_on_data.bundle_mode]
          price += add_on_data[price_mode]
        }else{
          if(add_on_data.bundle_mode == 'One Month'){
            price += add_on_data.price_one_month * vm.multiply_obj[vm.bundle_mode.bundle_mode]
          }else{
            var mode_cost = vm.cost_mode[add_on_data.bundle_mode]
            var bundle_cost = add_on_data[mode_cost]
            var days = vm.day_obj[add_on_data.bundle_mode]
            var amt_per_day = bundle_cost/days
            var cost_for_mode = amt_per_day *  vm.day_obj[vm.bundle_mode.bundle_mode]
            price += Number(Math.round(Number(cost_for_mode.toFixed(2))))
          }
        }
      })
      this.addon_price = price
      this.recommend_cost = this.ott_price + this.addon_price
	
    },
    createGroup () {
      this.BundleNameErrors = this.bundle_name ? [] : ['Bundle Name is required']
      this.BundleModeErrors = this.bundle_mode ? [] : ['Bundle Mode is required']
      this.SellerCostErrors = this.seller_cost ? [] : ['Seller Cost is required']
      this.OperatorMarginErrors = this.operator_margin ? [] : ['Operator margin cost is required']

      if (!this.formReady) {
        return
      }
      if(this.reseller_value.is_fixed_rate && this.reseller_value.enable_reseller_bundle_creation){
        if(this.seller_cost < this.addon_price){
          return Vue.notify({ text: 'Seller cost should be greater than or equal to '+ this.addon_price, type: 'error' })
        }
      }
      var id = this.guid(); var vm = this; var bundlepack = []
      if (this.select_bundle == 'Base Bundle') {
        var bundle = [...this.bundle, ...this.addonbundle]
        bundle.map(function (prop) {
          prop.bundle_id = id
          delete prop.id
          bundlepack = [...bundlepack, ...prop.bundlepackages]
        })
      } else {
        var bundle = this.addonbundle
        bundle.map(function (prop) {
          prop.bundle_id = id
          delete prop.id
          bundlepack = [...bundlepack, ...prop.bundlepackages]
        })
      }

      var uniquepack = bundlepack.filter(function (thing, index) {
        delete thing.id
        thing.bundle_id = id
        return index === bundlepack.findIndex(function (obj) {
          return obj.package_id === thing.package_id
        })
      })

      var operator_margin = (this.operator_margin == '') ? 0 : this.operator_margin
      if (operator_margin.length == 0) { return Vue.notify({ text: 'Please Add Operator Margin Cost', type: 'error' }) }
      var role_org_id = Vue.$cookies.get('org-id')
      if (this.select_bundle == 'Base Bundle') {
        if ((this.addonbundle.length != 0 && this.bundle.length != 0) || (this.bundle.length != 0)) {
          var payload = {
            bundle_id: id,
            bundle_name: this.bundle_name,
            bundlepackages: uniquepack,
            add_on: false,
            iptv: false,
            bundle_mode: this.bundle_mode.bundle_mode,
            bundle_type: 'resellerpackage',
            reseller_bundle_type: this.select_bundle,
            bundle_reseller_custom_packages: bundle,
            ott_price: this.ott_price,
            recommend_cost: Number(this.recommend_cost),
            seller_cost: Number(this.seller_cost),
            operator_margin: Number(operator_margin),
            org_id: role_org_id,
            allowed_device: this.allowed_device
          }
        } else if (this.addonbundle.length == 0 && this.bundle.length == 0) {
          return Vue.notify({ text: 'Select the bundle', type: 'error' })
        } else {
          return Vue.notify({ text: 'Atleast select 1 Bundle', type: 'error' })
        }
      } else {
        if (this.addonbundle.length == 0) {
          return Vue.notify({ text: 'Please select the addonbundle', type: 'error' })
        }
        var payload = {
          bundle_id: id,
          bundle_name: this.bundle_name,
          iptv: true,
          add_on: true,
          bundlepackages: uniquepack,
          bundle_mode: this.bundle_mode.bundle_mode,
          bundle_type: 'resellerpackage',
          reseller_bundle_type: this.select_bundle,
          bundle_reseller_custom_packages: bundle,
          recommend_cost: Number(this.recommend_cost),
          seller_cost: Number(this.seller_cost),
          org_id: role_org_id,
          operator_margin: Number(this.operator_margin),
          ott_price: 0,
	        allowed_device: this.allowed_device
        }
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/bundle', payload).then(responseData => {
        loader.hide()
        Vue.notify({ text: responseData.body, type: 'success' })
        this.list()
        this.getGroup()
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    updateGroup () {
      this.SellerCostErrors = this.seller_cost ? [] : ['Seller Cost is required']
      this.OperatorMarginErrors = this.operator_margin ? [] : ['Operator margin cost is required']

      if (!this.formReady) {
        return this.isshowmodal = true
      }
      if((this.reseller_value.is_fixed_rate && this.reseller_value.enable_reseller_bundle_creation) && (this.seller_cost<this.addon_price)){
        this.isshowmodal = true
        return Vue.notify({ text: 'Seller cost should be greater than or equal to '+this.addon_price, type: 'error' })
      }
      var payload = { seller_cost: this.seller_cost, operator_margin: this.operator_margin }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/bundle/' + this.bundle_id, payload).then(responseData => {
        loader.hide()
        Vue.notify({ text: responseData.body, type: 'success' })
        this.list()
        this.getGroup()
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    edit (row) {
      this.bundle_id = row.bundle_id
      this.bundle_name = row.bundle_name
      this.select_bundle = row.reseller_bundle_type
      this.bundle_mode = row.bundle_mode
      this.ott_price = row.ott_price
      this.recommend_cost = row.recommend_cost
      this.seller_cost = row.seller_cost
      this.operator_margin = row.operator_margin
      this.BundleNameErrors = []
      this.BundleModeErrors = []
      this.SellerCostErrors = []
      this.OperatorMarginErrors = []
      this.allowed_device = row.allowed_device
      this.addon_price = row.recommend_cost-row.ott_price
      var pack = ''; var add_on_pack = ''; var vm = this
      row.bundle_reseller_custom_packages.map(function (re_bundle) {
        vm.addonFlag = !!(re_bundle.iptv)
        if (!re_bundle.iptv && !re_bundle.add_on) {
          if (pack === '') {
            pack = re_bundle.bundle_name
          } else {
            pack = pack + ' , ' + re_bundle.bundle_name
          }
        } else {
          if (add_on_pack === '') {
            add_on_pack = re_bundle.bundle_name
          } else {
            add_on_pack = add_on_pack + ' , ' + re_bundle.bundle_name
          }
        }
        vm.base_bundle_name = pack
        vm.add_on_bundle_name = add_on_pack
      })
      this.isshowgrid = true
      this.isshowForm = false
      this.isshowmodal = true
    },
    add () {
      this.title = 'Create Custom Bundle'
      this.bundle_name = ''
      this.BundleNameErrors = []
      this.select_bundle = 'Base Bundle'
      this.bundle = []
      this.addonbundle = []
      this.ott_price = 0
      this.recommend_cost = 0
      this.seller_cost = 0
      this.SellerCostErrors = []
      this.bundle_mode = ''
      this.BundleModeErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isshowmodal = false
      this.operator_margin = 0
      this.OperatorMarginErrors = []
      this.allowed_device = ''
    },
    list () {
      this.getGroup()
      this.isshowgrid = true
      this.isshowForm = false
      this.isshowmodal = false
    },
    search: function (term) {
      this.term = term
    },
  },
}
</script>

<style lang="scss">
</style>
